/* Forms */

label {
  font-weight: 600;
}

input {
  cursor: text !important;
}

/* custom-input */
.label-input {
  color: $font-color !important;
}

// input filed
.input-filed {
  margin-bottom: 25px;
  position: relative;
}

.custom-input {
  border: 1px solid $color-secondary;
  padding: 8px 12px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  color: $font-color-primary !important;
}
.custom-input::placeholder {
  font-size: 14px !important;
  color: $font-color-primary !important;
}

.custom-input:hover {
  border: 1px solid $color-primary;
}

.custom-input:focus {
  color: $font-color !important;
}
.invalid-svg {
  width: 14px;
}
.custom-input::placeholder {
  color: $font-color-secondary;
}

.custom-input:disabled {
  background-color: $color-secondary !important;
  color: $font-color !important;
  border: none !important;
}
//   error message
.input-error {
  border: 1px solid $color-error !important;
  padding: 12px, 16px, 12px, 16px;
  border-radius: 8px !important;
}
.datePicker-error {
  border: 1px solid $color-error !important;
  border-radius: 8px !important;
}
.invalid-error {
  color: $color-error;
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  position: absolute;
  display: flex;
  align-items: center;
}
//  custom phone input

.phone-input {
  border: 1px solid $color-secondary !important;
  padding: 12px, 16px, 12px, 16px;
  border-radius: 8px !important;
}

.phone-input:hover {
  border: 1px solid $color-primary !important;
}

.phone-input:disabled {
  background-color: $color-secondary !important;
}
// .PhoneInputInput:focus{
//   outline: 0px solid $font-color !important;

// }
.PhoneInput:focus {
  outline: 1px solid $font-color !important;
}
/* search input */

.search {
  border: 1px solid $color-secondary;
  padding: 10px, 16px, 10px, 16px;
  border-radius: 8px !important;
  margin-right: 10px;
  position: relative;
  z-index: 0 !important;
}

.search:focus {
  border: 1px solid $font-color-primary !important;
  color: $font-color-primary !important;
}
.search:hover {
  border: 1px solid $color-primary !important;
}
.search::placeholder {
  color: $font-color-secondary;
}

.custom-search-wrap {
  position: relative;
  border-radius: 8px;
  width: 400px !important;
  height: 40px;

  @include max-screen($break-medium) {
    width: 270px !important;
  }
  @include max-screen($break-small) {
    width: 240px !important;
  }
}

.search-icon {
  position: absolute;
  top: 8px;
  right: 18px;
  cursor: pointer;
  color: $font-color-secondary;
  cursor: pointer !important;
  z-index: 1 !important;
  padding-right: 5px;
}
[dir="rtl"] .search-icon {
  right: unset;
  left: 18px;
}
.chat-search-icon {
  position: absolute;
  top: 25px;
  right: 37px;
  cursor: pointer;
  color: $font-color-secondary;
  cursor: pointer !important;
  z-index: 1 !important;
}
[dir="rtl"] .chat-search-icon {
  right: unset;
  left: 37px;
}

/* custom radio btn */

.custom-radio-btn {
  border: 1px solid $color-secondary;
  padding: 12px, 16px, 12px, 16px;
  border-radius: 8px !important;
  min-width: 200px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.custom-radio-btn:checked::after {
  border: 1px solid $color-primary !important;
}

// file upload
.custom-file {
  border: 1px solid $color-secondary;
  padding: 24px, 16px, 24px, 16px;
  border-radius: 8px !important;
  height: auto;
}
.custom-file:hover {
  outline: 1px solid $color-primary;
  // outline: 0;
}
.custom-file:focus {
  outline: 1px solid $color-primary;
}

.custom-file-error {
  border: 1px solid $color-error;
  padding: 24px, 16px, 24px, 16px;
  border-radius: 8px !important;
  height: auto;
}

/* radio-circle */
.radio-circle[type="radio"] {
  border: 1px solid $color-secondary !important;
}

.radio-border {
  border: 1px solid $color-primary !important;
}
// custom dropdown
div:has(> .select-custom) {
  display: inline-block;
}

.select-custom {
  border: 1px solid $color-secondary !important;
  background-color: transparent;
  border-radius: 8px !important;
  padding: 10px, 16px, 10px, 16px !important;
  box-shadow: none !important;
  min-height: 40px !important;
  &:focus {
    @include box-shadow(none);
    // outline: 1px solid $color-primary !important;
    border-color: $color-primary !important;
  }
}
.dropdown-height .select-custom {
  min-height: 40px !important;
}

.select-custom::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: $font-color !important;
  padding: 10px, 16px, 10px, 16px !important;
}

.select-custom .react-dropdown-select-dropdown {
  background-color: $col-white;
  border-radius: 8px;
  border: 1px solid $border-color !important;
  min-width: 235px;
  cursor: pointer !important;
}

.select-custom .react-dropdown-select-content {
  flex-wrap: nowrap;
}

.select-custom .react-dropdown-select-content span {
  color: $font-color !important;
}
.select-custom:hover {
  border: 1px solid $color-primary !important;
}

.select-custom-2 .react-dropdown-select-dropdown {
  width: fit-content;
  left: auto;
  right: -1px;
}

[dir="rtl"] .select-custom-2 .react-dropdown-select-dropdown {
  direction: rtl !important;
  width: fit-content;
  right: auto;
  left: -1px;
}

.dropdown-left .react-dropdown-select-dropdown {
  left: -150px !important;
}

[dir="rtl"] .dropdown-left .react-dropdown-select-dropdown{
  left: -1px;
}

.select-bulk .react-dropdown-select-dropdown {
  min-width: 100%;
}

.w-fit .react-dropdown-select-dropdown {
  width: fit-content;
}

.search-form {
  position: relative;
  @extend .padding-0;
  input {
    background-color: #f5f6f8;
    border: none;
    padding-left: 40px !important;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .btn {
    @include box-shadow(none);

    position: absolute;
    top: 10px;
    left: 15px;
    @extend .padding-0;
    border: none;
    color: #1f1f1f66;
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: inherit;
      border-left: none;
      border-color: inherit;
    }
  }
}

input[type="checkbox"] {
  accent-color: $color-primary;
}
/* fancy checkbox and radio button */
.fancy-checkbox {
  margin-right: 10px;

  &,
  label {
    font-weight: normal;
  }

  input[type="checkbox"] {
    display: none;

    + span {
      &:focus-visible {
        border: none !important;
        outline: 0px !important;
      }
      &:focus {
        &::before {
          border: 2px solid #6418c3 !important;
        }
      }

      @include inline-block;

      cursor: pointer;
      position: relative;

      &:before {
        @include inline-block;

        position: relative;
        bottom: 1px;
        width: 15px !important;
        height: 15px !important;
        content: "";
        border: 1px solid #9fa9b6 !important;
        border-radius: 3px !important;
        margin-right: 5px;
      }
    }

    &:checked + span:before {
      font-family: FontAwesome;
      content: url("../../../Icon/check-box.svg");
      color: $col-white;
      text-align: center !important;
      border: none !important;
      width: 15px !important;
      height: 17px !important;
    }
  }
  .fancy-checkbox {
    input[type="checkbox"] {
      &:checked + span:before {
        // background: #6418c3;
        // border-color: #6418c3;
        margin-right: 5px;
      }
    }
  }
  &.custom-color-green input[type="checkbox"] {
    &:focus::before {
      border: 1px solid #9fa9b6 !important;
    }
    &:checked + span:before {
      color: $color-success;
      background-color: $col-white;
      width: 15px !important;
      height: 15px !important;
    }
  }

  &.custom-bgcolor-green input[type="checkbox"] {
    &:checked + span:before {
      color: $col-white;
      background-color: $color-success;
      border-color: darken($color-success, 8%);
      width: 15px !important;
      height: 15px !important;
    }
  }
}
[dir="rtl"] .fancy-checkbox input[type="checkbox"] + span:before {
  margin-left: 5px;
  margin-right: unset;
}
.fancy-radio {
  margin-right: 10px;

  &,
  label {
    font-weight: normal;
  }

  input[type="radio"] {
    display: none;

    + span {
      display: block;
      cursor: pointer;
      position: relative;

      i {
        @include inline-block;
        @include border-radius(8px);

        position: relative;
        bottom: 1px;
        content: "";
        border: 1px solid #ccc;
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }

    &:checked + span i:after {
      @include border-radius(50%);

      display: block;
      position: relative;
      top: 3px;
      left: 3px;
      content: "";
      width: 10px;
      height: 10px;
      background-color: #6418c3;
    }
    [dir="rtl"] &:checked + span i:after {
      right: 3px;
    }
  }

  &.custom-color-green input[type="radio"] {
    &:checked + span i:after {
      background-color: #53d76a;
    }
  }

  &.custom-bgcolor-green input[type="radio"] {
    &:checked + span i {
      background-color: #53d76a;

      &:after {
        background-color: $col-white;
      }
    }
  }
}
.fancy-radio:focus {
  outline: 1px solid $color-primary;
}
.fancy-radio:focus-visible {
  outline: 1px solid $color-primary;
}
/* fancy checkbox and fancy radio */
.input-group-addon {
  .fancy-radio,
  .fancy-checkbox {
    margin: 0;
    position: relative;
    top: 1px;
  }

  .fancy-radio input[type="radio"] + span i,
  .fancy-checkbox input[type="checkbox"] + span {
    margin: 0;

    &:before {
      margin: 0;
    }
  }
}

.form-control {
  @include box-shadow(none);
  background-color: $col-white;
  font-size: $font-size;
  border-radius: 8px;
  outline: none !important;

  &:focus {
    @include box-shadow(none);
    // outline: 1px solid $color-primary !important;
    border-color: $color-primary !important;
  }
}

.help-block {
  font-size: 13px;
  color: #929292;
}

/* multiselect checkbox */
.multiselect-custom + .btn-group ul.multiselect-container {
  > li {
    > a {
      padding: 5px 15px;
      display: block;
      outline: none;

      label.checkbox {
        padding: 0;
        font-size: 14px;

        &:before {
          @include inline-block;

          position: relative;
          bottom: 1px;
          content: "";
          width: 16px;
          height: 16px;
          margin-right: 10px;
          border: 1px solid #ccc;
          background-color: #fafafa;
        }

        input[type="checkbox"] {
          display: none;
        }
      }
    }

    &.active > a {
      label.checkbox:before {
        font-family: FontAwesome;
        content: "\f00c";
        font-size: 12px;
        color: #99a1a7;
        text-align: center;
        line-height: 15px;
        background-color: $grey-200;
      }
    }
    &.active {
      .checkbox {
        color: $col-white;
      }
    }
    &:hover {
      background-color: $grey-100;
    }
  }
}

/* multiselect radio */
.multiselect-custom + .btn-group ul.multiselect-container {
  > li {
    > a {
      padding: 5px 15px;
      display: block;
      outline: none;

      label.radio {
        padding: 0;

        &:before {
          @include inline-block;
          @include border-radius(8px);

          position: relative;
          bottom: 1px;
          content: "";
          width: 16px;
          height: 16px;
          margin-right: 10px;
          border: 1px solid #ccc;
          background-color: #fafafa;
        }

        input[type="radio"] {
          display: none;
        }
      }
    }

    &.active > a {
      label.radio:before {
        font-family: FontAwesome;
        content: "\f111";
        color: #99a1a7;
        font-size: 8px;
        text-align: center;
        line-height: 15px;
      }
    }

    &.active {
      .radio {
        color: $col-white;
      }
    }

    &:hover {
      background-color: $grey-100;
    }
  }
}

.multiselect-search {
  @include border-right-radius(2px !important);
}

.btn.multiselect-clear-filter {
  @include box-shadow(none);

  position: absolute;
  top: 2px;
  right: 0;
  padding-left: 0;
  padding-right: 0;
  border: none;
  background: transparent;
  z-index: 9 !important;
  outline: none;

  &:hover,
  &:focus {
    background: transparent;
    outline: none;
  }
}

/* date picker */
.datepicker table tr td.active {
  &.active,
  &.highlighted.active,
  &.highlighted:active,
  &.active:active {
    background-color: $color-accent;
    border-color: darken($color-accent, 8%);
  }
}

/* parsley js */
.parsley-errors-list {
  margin-top: 8px;
  list-style-type: none;
  line-height: 0.9em;
  padding-left: 5px;

  li {
    color: $color-danger;
    font-size: 0.9em;
    margin-top: 3px;
  }
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
  background-color: #fbf5f5;
  border-color: darken(#fbf5f5, 8%);
}

.form-control {
  &.parsley-error:focus {
    border-color: darken(#fbf5f5, 18%);
  }

  &.parsley-success:focus {
    border-color: darken(#dff0d8, 16%);
  }
}

.md-editor > textarea {
  padding: 10px;
}
textarea {
  padding: 10px !important;
  outline: 0px !important;
  min-height: 100px !important;

  &.text-area {
    width: 100%;
    border: 1px solid $color-secondary;
    padding: 8px 12px !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    height: auto;
  }
  &.text-area:hover {
    border: 1px solid $color-primary;
  }

  &.text-area:focus {
    border: 1px solid $color-primary !important;
    color: $font-color !important;
  }
}

.dropify-wrapper {
  @include border-radius(3px);

  background-color: #f9f9f9;
  color: #b9b9b9;
  border: 1px solid #e4e4e4;

  .dropify-preview {
    background-color: #f9f9f9;
  }
}

select.form-control:not([size]):not([multiple]) {
  height: 36px;
}
.form-check-input:checked {
  background-color: $color-primary;
  border-color: $color-primary;
}
.box-area {
  overflow-x: hidden;
  overflow-y: scroll;
}
.box-area::-webkit-scrollbar {
  width: 5px !important;
  display: none !important;
}
input[type="checkbox"] {
  cursor: pointer !important;
}
.table-container::-webkit-scrollbar {
  width: 6px;
  background-color: transparent !important;
}
.table-container::-webkit-scrollbar-thumb {
  width: 5px !important;
  background: $color-secondary;
  border-radius: 4px;
}
.table-container::-webkit-scrollbar-track {
  // background: #f1f1f1;
  border-radius: 8px;
}
.table-container::-webkit-scrollbar-thumb:hover {
  background: #d5d6da !important;
}
// avatar font
.avatar-font {
  background-color: $border-color !important;
  color: $font-color !important;
  font-weight: 600;
  font-size: 14px !important;
  image-rendering: pixelated !important;
  line-height: 20px !important;
  height: 40px !important;
  width: 40px !important;
}

// Top dropdown input
.select-school .react-dropdown-select-input {
  width: 100% !important;
  margin: 0 !important;
}

// test
// input[type=checkbox]:focus {
//   outline: 1px solid #6418c3;
// }
.table-container {
  height: calc(100vh - 312px);
  overflow: hidden;
  overflow-y: scroll;
}
